import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import { IProject } from "../interfaces"

import { ProjectList } from "./project-list"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    media: {
      height: 140,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    card: {
      textAlign: "left",
    },
    cn: {
      display: "table-cell",
      width: "100vw",
      textAlign: "center",
    },
  });

interface Props extends WithStyles<typeof styles> {}

const Projects: React.FC<Props> = props => {

  const { classes } = props

  const [projects,] = React.useState<Array<IProject>>(ProjectList)

  return (
      <div className={classes.cn}>

        {
          projects.map((item: IProject, idx: number) => (

            <Card key={idx} raised style={{margin: "50px 75px 50px 75px"}}>

              <CardActionArea href={item.linkUrl}>
                <CardMedia
                  className={classes.media}
                  image={item.imageUrl}
                  title={item.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {item.description}
                  </Typography>
                </CardContent>
              </CardActionArea>

            </Card>

          ))
        }
        
        
      </div>

  );

}

export default withStyles(styles)(Projects);

