export const ProjectList = [
    {
        title: "Twitter Personality Insights",
        description: "Use the IBM Personality Insight services to generate a Big 5 profile of a Twitter user.",
        imageUrl: "https://help.twitter.com/content/dam/help-twitter/brand/logo.png",
        content: "placeholder",
        linkUrl: "https://gitlab.com/happinessengineering/twitter-personality-insights"
    },
    {
        title: "RaspberryPi Vibration Monitor",
        description: "Add a vibration sensor to a RaspberryPi, with notifications and data visualization.",
        imageUrl: "https://gloimg.gbtcdn.com/storage/item/6668111396303740928/15946/a9775428c4ce.jpg_500x500.jpg",
        content: "placeholder",
        linkUrl: "https://gitlab.com/happinessengineering/rpi-vibration-monitor"
    },
    {
        title: "Wireguard Metrics Daemon",
        description: "Dirt simple daemon to fetch and serve Wireguard connection status via a standard REST API.",
        imageUrl: "https://cdn.findyourvpn.com/2020/02/Wireguard-Logo-880x360.png",
        content: "placeholder",
        linkUrl: "https://gitlab.com/happinessengineering/wg-metricsd"
    }
] 