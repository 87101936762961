import React, { useEffect } from 'react';
import {
  ButtonGroup,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

import ReactMarkdown from 'react-markdown'

import useGlobal from '../store/store'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      textAlign: "left",
    }
  });

interface Props extends WithStyles<typeof styles> {}

const Cv: React.FC<Props> = props => {

  const { classes } = props; 

  const [, globalActions] = useGlobal()

  const [markdownCv, setMarkdownCv] = React.useState("")

  useEffect(() => {
  
    globalActions.setLoading(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
  
    fetch('/cv.md')
      .then(response => response.text())
      .then((data) => {
        setMarkdownCv(data)
        globalActions.setLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (

    <>

    {
      markdownCv !== "" ?
        <Card style={{marginBottom: 20}}>
          <ButtonGroup style={{margin: 10, float:"right"}}>
            <Button download href="/cv.md" variant="contained">.md</Button>
            <Button download href="/cv.pdf" variant="contained">.pdf</Button>
          </ButtonGroup>
          <CardContent className={classes.card}>
            <ReactMarkdown source={markdownCv} />
          </CardContent>
        </Card>
      : 
        null
    }

    </>

  );

}

export default withStyles(styles)(Cv);

