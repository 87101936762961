import { Store } from 'use-global-hook';

import { appGlobalState } from './interface'

// Associated actions are what's expected to be returned from globalHook
// 
// NOTE - CHANGE THE 'value' TYPE IF THE DEFAULT 'string' IS NOT VALID!
//
export type associatedActions = {
    setMainBackgroundImageUrl: (value: string) => void;
    setDrawerOpen: (value: boolean) => void;
    setLoading: (value: boolean) => void;
  };
  
  // setValue will be returned by globalHook as setValue.bind(null, store)
  // This is one reason we have to declare a separate associated actions type
export const setMainBackgroundImageUrl = ( store: Store<appGlobalState, associatedActions>, value: string ) => {
  store.setState({ ...store.state, mainBackgroundImageUrl: value });
};
export const setDrawerOpen = ( store: Store<appGlobalState, associatedActions>, value: boolean ) => {
  store.setState({ ...store.state, drawerOpen: value });
};
export const setLoading = ( store: Store<appGlobalState, associatedActions>, value: boolean ) => {
  store.setState({ ...store.state, loading: value });
};
 
  // actions passed to globalHook do not need to be typed
export const actions = {
    setMainBackgroundImageUrl,
    setDrawerOpen,
    setLoading
  };

