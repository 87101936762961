import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/MailOutline'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      bottom: 20,
      right: 20,
      textAlign: "left",
      minWidth: 275,
      [theme.breakpoints.up('xs')]: {
        maxWidth: "80vw",
        margin: "auto"
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500,
        position: "absolute"
      }
    },
    cn: {
      [theme.breakpoints.up('xs')]: {
        display: "table-cell",
        width: "100vw",
        height: "80vh",
        verticalAlign: "middle",
        textAlign: "center",
      }
    },
    title: {
      fontSize: 14,
    }
  });

interface Props extends WithStyles<typeof styles> {}

const Contact: React.FC<Props> = props => {

  const { classes } = props

  return (
    
    <div className={classes.cn}>
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          (Get in) touch.
        </Typography>
        <Typography variant="body2" component="p">
          If you can't possibly contain your raging torrent of opinion and thought.
          <br />
          If you believe passionately - to the death - in your cause.
          <br />
          If you've ruminated deeply and across vast oceans of time.
          <br />
          If you've tried to break down your position with opposition.
          <br />
          If you bring solutions, not problems.
          <br />
          If your philosophy sqaures with your practice.
          <br />
          If you *absolutely must*...
          <br />
          (... or if you just want to say hi!)

          <br /><br />
            <EmailIcon style={{verticalAlign:"middle", marginRight: 5}} />
            <a href="mailto:happinessbot@happiness.engineering">happinessbot at happiness dot engineering</a>
        </Typography>
      </CardContent>
    </Card>
    </div>

  );

}

export default withStyles(styles)(Contact);