import React from "react"
import { Route, Switch } from "react-router-dom"
import Home from "./pages/home"
import Contact from "./pages/contact"
import Cv from "./pages/cv"
import Projects from "./pages/projects"
import NotFound from "./pages/notFound"

export default () =>
    <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/cv" exact component={Cv} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/contact" exact component={Contact} />
        <Route component={NotFound} />
    </Switch>

