import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton'
import AboutIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import MailIcon from '@material-ui/icons/MailOutlined'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeopleOutlined'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import BuildIcon from '@material-ui/icons/BuildOutlined'

import { useHistory } from 'react-router-dom'

import useGlobal from '../store/store'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
    button: {
      margin: theme.spacing(1),
    },
    cn: {
        display: "table-cell",
        width: "100vw",
        height: "80vh",
        verticalAlign: "middle",
        textAlign: "center",
    },
    inner: {
        margin: 10,
        display: "inline-block",
        [theme.breakpoints.up('xs')]: {
          maxWidth: "80vw",
        },
    },
    author: {
      color: "#000000",
      fontFamily: "Garamond, Georgia, serif",
      fontSize: 25,
      width: "90vw",
      textAlign: "right",
      [theme.breakpoints.up('xs')]: {
        textAlign: "center"
      },
    },
    card: {
      opacity: 0.5,
      maxHeight: "75vh",
      overflowY: "auto"
    }

  });

interface Props extends WithStyles<typeof styles> {}

const Home: React.FC<Props> = props => {

    const { classes } = props;

    const history = useHistory()

    const [globalState,] = useGlobal()

    return (
      <div className={classes.cn}>

            <ButtonGroup variant="contained">
              <Button
                onClick={() => history.push("/cv")}>
                <EmojiPeopleIcon fontSize="large" style={{margin:10}}/>
              </Button>
              <Button
                onClick={() => history.push("/projects")}>
                <BuildIcon fontSize="large" style={{margin:10}}/>
              </Button>
              <Button
                onClick={() => history.push("/contact")}>
                <MailIcon fontSize="large" style={{margin:10}}/>
              </Button>
            </ButtonGroup>

        <Tooltip 
          title={globalState.mainBackgroundImageUrl}
        >
          <IconButton
            style={{
              position: "absolute",
              bottom: 0,
              right: 0
            }}
            onClick={() => window.open(globalState.mainBackgroundImageUrl)}>
          <AboutIcon
            fontSize="large"
          />
          </IconButton>
        </Tooltip>

      </div>

  );

}

export default withStyles(styles)(Home);

